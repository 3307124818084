import { createSlice } from "@reduxjs/toolkit";
import ApiClientConnector from "src/api/ApiClientConnector";

const initialState = {
    bankAccounts: [],
    selectedBankAccount: null
};

export const CustomerBankAccountSlice = createSlice({
    name: 'customer-bank-accounts',
    initialState,
    reducers: {
        getCustomerBankAccounts: (state, action) => {
            state.bankAccounts = action.payload;
        },
        addNewCustomerBankAccount: (state, action) => {
            state.bankAccounts = [...state.bankAccounts, {...action.payload}];
        },
        updateExistCustomerBankAccount: (state, action) => {
            const index = state.bankAccounts.findIndex(i => i.id === action.payload.id);
            state.bankAccounts[index] = {...action.payload};
        },
        deleteExistCustomerBankAccount: (state, action) => {
            const index = state.bankAccounts.findIndex(i => i.id === action.payload.id);
            state.bankAccounts.splice(index, 1);
        },
        setSelectedCustomerBankAccount: (state, action) => {
            state.selectedBankAccount = action.payload;
        }
    }
})

export const { getCustomerBankAccounts, setSelectedCustomerBankAccount, addNewCustomerBankAccount, updateExistCustomerBankAccount, deleteExistCustomerBankAccount } = CustomerBankAccountSlice.actions;

export const fetchCustomerBankAccounts = (instance, customerId, criteria) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.customerBankAccounts.get(customerId, criteria)
        dispatch(getCustomerBankAccounts(response));
        return response;
    } catch (error){
        throw error;
    }
}

export const addCustomerBankAccount = (instance, data, customerId) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.customerBankAccounts.post(customerId, data);
        dispatch(addNewCustomerBankAccount(response));
        return response;
    }
    catch (error){
        throw error;
    }
}

export const updateCustomerBankAccount = (instance, data, customerId) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.customerBankAccounts.put(customerId, data.id, data);
        dispatch(updateExistCustomerBankAccount(response));
        return response;
    }
    catch (error){
        throw error;
    }
}

export const deleteCustomerBankAccount = (instance, id, customerId) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.customerBankAccounts.delete(customerId, id);
        dispatch(deleteExistCustomerBankAccount(response));
        return response;
    } catch (error){
        throw error;
    }
}

export default CustomerBankAccountSlice.reducer;