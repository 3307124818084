import { createSlice } from "@reduxjs/toolkit";
import ApiClientConnector from "src/api/ApiClientConnector";

const initialState = {
    facilityZones: [],
    selectedFacilityZones: null
};

export const FacilityZoneSlice = createSlice({
    name: 'facility-zone',
    initialState,
    reducers: {
        getFacilityZones: (state, action) => {
            state.facilityZones = action.payload;
        },
        addNewFacilityZones: (state, action) => {
            state.facilityZones = [...state.facilityZones, {...action.payload}];
        },
        updateExistFacilityZones: (state, action) => {
            const index = state.facilityZones.findIndex(i => i.id === action.payload.id);
            state.facilityZones[index] = {...action.payload};
        },
        setSelectedFacilityZones: (state, action) => {
            state.selectedFacilityZones = action.payload;
        }
    }
})

export const { getFacilityZones, addNewFacilityZones, updateExistFacilityZones, setSelectedFacilityZones } = FacilityZoneSlice.actions;

export const fetchFacilityZones = (instance, facilityId, criteria) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.facilityZones.get(facilityId, criteria)
        dispatch(getFacilityZones(response));
        return response;
    } catch (error){
        throw error;
    }
}

export const addFacilityZone = (instance, facilityId, data) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.facilityZones.post(facilityId, data);
        dispatch(addNewFacilityZones(response));
        return response;
    } catch (error){
        throw error;
    }
}

export const updateFacilityZone = (instance, facilityId, data) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.facilityZones.put(facilityId, data.id, data);
        dispatch(updateExistFacilityZones(response));
        return response;
    } catch (error){
        throw error;
    }
}

export default FacilityZoneSlice.reducer;