import { createSlice } from '@reduxjs/toolkit';
import ApiClientConnector from 'src/api/ApiClientConnector';

const initialState = {
    standards: [],
    compositions: [],
    totalPercentage: 0,
    eligibleStandards: [],
    error: ''
};
export const ManageStandardsSlice = createSlice({
    name: 'standards',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },
        getStandardsList: (state, action) => {
            state.standards = action.payload;
        },
        getEligibleStandards: (state, action) => {
            state.eligibleStandards = action.payload;
        },
        addNewStandard: (state, action) => {
            state.standards = [...state.standards, action.payload];
        },
        addComposition: (state, action) => {
            const composition = state.compositions.find((composition) => composition.referenceStandardId === action.payload.referenceStandardId);
            state.totalPercentage = parseFloat(state.totalPercentage) +  parseFloat(action.payload.percentage);
            if(composition === undefined) {
                state.compositions = [...state.compositions, action.payload];
            } else {
                const newCompositions = state.compositions.map((item) => {
                if(composition.referenceStandardId === item.referenceStandardId) {
                  return {
                    ...item,
                    Percentage: parseFloat(item.percentage) + parseFloat(action.payload.percentage)
                  } 
                } else {
                  return item
                }
              });
              state.compositions = newCompositions;
            }
        },
        removeComposition: (state, action) => {
            const composition = state.compositions.find((composition) => composition.referenceStandardId === action.payload);
            state.totalPercentage = parseFloat(state.totalPercentage) - parseFloat(composition.percentage);
            const index = state.compositions.findIndex((composition) => composition.referenceStandardId === action.payload);
            state.compositions.splice(index, 1);
        },
        changeCompositionState: (state, action) => {
            state.compositions = action.payload.compositions;
            state.totalPercentage = 0;
            if(action.payload.details != null) {
                action.payload.details.forEach((detail) => {
                    state.totalPercentage = parseFloat(state.totalPercentage) + parseFloat(detail.percentage);
                });
            }
        },
        updateStandards: (state, action) => {
            var index = state.standards.findIndex(i => i.id === action.payload.id);
            state.standards[index] = {...action.payload};
        },
        getStandard: (state, action) => {
            const newComposition = {
                code: action.payload.data.code,
                referenceStandardId: action.payload.data.id,
                description: action.payload.data.description,
                percentage: parseFloat(action.payload.percentage),
            }
            state.compositions = [...state.compositions, newComposition];
        }
    },
});

export const {
    hasError,
    getStandardsList,
    getEligibleStandards,
    addNewStandard,
    addComposition,
    removeComposition,
    changeCompositionState,
    updateStandards,
    getStandard,
} = ManageStandardsSlice.actions;

export const fetchStandards = (instance, criteria) => async (dispatch) => {
    try {
        const client = await ApiClientConnector(instance);
        const result = await client.standards.get(criteria);
        dispatch(getStandardsList(result));
    } catch (error) {
        dispatch(hasError(error));
    }
}

export const fetchEligibleStandards = (instance, id, customerId, criteria) => async (dispatch) => {
    try {
        const client = await ApiClientConnector(instance);
        const result = await client.standards.getEligibleToAssign({
            standardId: id,
            customerId: customerId
        }, criteria);
        dispatch(getEligibleStandards(result));
        return result;
    } catch (error) {
        dispatch(hasError(error));
    }
}

export const fetchStandard = async(instance, id, percentage) => async (dispatch) => {
    try {
        const client = await ApiClientConnector(instance);
        const result = await client.standards.get({
            top: 1,
            select: `Id,Code,Description`,
            filter: `Id eq '${id}'`
        });
        if (result.length > 0){
            try{
                dispatch(getStandard({data: result[0], percentage: percentage}));
                return result[0];
            }catch(error){
                throw error;
            }
        }
    } catch (error) {
        throw error;
    }
}

export const fetchStandardById = (instance, data) => async () => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.standards.getById(data);
        return response;
    } catch (error){
        throw error;
    }
}

export const addStandard = (instance, data) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.standards.post(data);
        dispatch(addNewStandard(response));
        return response;
    } catch (error){
        throw error;
    }
}

export const updateStandard = (instance, data, id) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.standards.put(id,data);
        dispatch(updateStandards(response));
        return response;
    } catch (error){
        throw error;
    }
}


export default ManageStandardsSlice.reducer;