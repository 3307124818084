import { createSlice } from "@reduxjs/toolkit";
import ApiClientConnector from "src/api/ApiClientConnector";

const initialState = {
};

export const WarehouseLocationSlice = createSlice({
    name: 'warehouse-location',
    initialState,
    reducers: {
    }
})

export const updateWarehouseLocation = (instance, id, data) => async () => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.warehouseLocations.put(id, data);
        return response;
    }
    catch (error){
        throw error;
    }
}

export default WarehouseLocationSlice.reducer;