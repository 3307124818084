import { createSlice } from "@reduxjs/toolkit";
import ApiClientConnector from "src/api/ApiClientConnector";

const initialState = {
    contacts: [],
    selectedContact: null
};

export const CustomerContactSlice = createSlice({
    name: 'customer-contacts',
    initialState,
    reducers: {
        getCustomerContacts: (state, action) => {
            state.contacts = action.payload;
        },
        addNewCustomerContact: (state, action) => {
            state.contacts = [...state.contacts, {...action.payload}];
        },
        updateExistCustomerContact: (state, action) => {
            const index = state.contacts.findIndex(i => i.id === action.payload.id);
            state.contacts[index] = {...action.payload};
        },
        deleteExistCustomerContact: (state, action) => {
            const index = state.contacts.findIndex(i => i.id === action.payload.id);
            state.contacts.splice(index, 1);
        },
        setSelectedCustomerContact: (state, action) => {
            state.selectedContact = action.payload;
        }
    }
})

export const { getCustomerContacts, setSelectedCustomerContact, addNewCustomerContact, updateExistCustomerContact, deleteExistCustomerContact } = CustomerContactSlice.actions;

export const fetchCustomerContacts = (instance, customerId, criteria) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.customerContacts.get(customerId, criteria)
        dispatch(getCustomerContacts(response));
        return response;
    } catch (error){
        throw error;
    }
}

export const addCustomerContact = (instance, data, customerId) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.customerContacts.post(customerId, data);
        dispatch(addNewCustomerContact(response));
        return response;
    }
    catch (error){
        throw error;
    }
}

export const updateCustomerContact = (instance, data, customerId) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.customerContacts.put(customerId, data.id, data);
        dispatch(updateExistCustomerContact(response));
        return response;
    }
    catch (error){
        throw error;
    }
}

export const deleteCustomerContact = (instance, id, customerId) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.customerContacts.delete(customerId, id);
        dispatch(deleteExistCustomerContact(response));
        return response;
    } catch (error){
        throw error;
    }
}

export default CustomerContactSlice.reducer;