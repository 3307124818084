import { createSlice } from "@reduxjs/toolkit";
import ApiClientConnector from "src/api/ApiClientConnector";

const initialState = {
    countryOriginsList: [],
    selectedCountryOrigin: null
};

export const ProductCountryOriginsSlice = createSlice({
    name: 'productCountryOrigins',
    initialState,
    reducers: {
        getCountryOrigins: (state, action) => {
            state.countryOriginsList = action.payload;
        }
    }
});

export const { getCountryOrigins } = ProductCountryOriginsSlice.actions;

export const fetchCountryOrigins = (instance, criteria) => async (dispatch) => {
    try {
        const client = await ApiClientConnector(instance);
        const response = await client.productCountryOrigins.get(criteria);
        dispatch(getCountryOrigins(response));
        return response;
    } catch (error) {
        throw error;
    }
};

export default ProductCountryOriginsSlice.reducer;