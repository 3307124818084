import { createSlice } from '@reduxjs/toolkit';
import apiClient from '../../api/ApiClientConnector';

const initialState = {
  users: [],
  error: ''
};

export const UsersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },
    // GET Users
    getUsers: (state, action) => {
      state.users = action.payload;
    }
  },
});
export const {
  hasError,
  getUsers,
} = UsersSlice.actions;

export const fetchUsers = (instance, criteria) => async (dispatch) => {    
  try {
    const client = await apiClient(instance);
    const result = await client.users.get(criteria);
    dispatch(getUsers(result));
    return result;
  } catch (error) {
    dispatch(hasError(error));
  }
};

export default UsersSlice.reducer;
