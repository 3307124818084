import { createSlice } from "@reduxjs/toolkit"
import ApiClientConnector from "src/api/ApiClientConnector";

const initialState = {
    gradeList: [],
}

export const GradeSlice = createSlice({
    name: "grade",
    initialState,
    reducers: {
        getGrades: (state, action) => {
            state.gradeList = action.payload;
        }
    }
})

export const { getGrades } = GradeSlice.actions;

export const fetchGrades = (instance, criteria) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.grades.get(criteria);
        dispatch(getGrades(response));
        return response;
    }
    catch (error){
        throw error;
    }
}

export default GradeSlice.reducer;