import { createSlice } from '@reduxjs/toolkit';
import apiClient from './../../api/ApiClientConnector';

const initialState = {
  events: [],
  error: ''
};

export const AuditEventsSlice = createSlice({
  name: 'audit-events',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    setEvents(state, action) {
      state.events = action.payload;
    }
  },
});

export const { hasError, setEvents } = AuditEventsSlice.actions;

export const fetchEvents = (instance, criteria) => async (dispatch) => {    
  try {
    const client = await apiClient(instance);
    const result = await client.auditEvents.get(criteria);
    dispatch(setEvents(result));
  } catch (error) {
    dispatch(hasError(error.toString()));
  }
};

export default AuditEventsSlice.reducer;