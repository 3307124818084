import { createSlice } from "@reduxjs/toolkit"
import ApiClientConnector from "src/api/ApiClientConnector";

const initialState = {
    proformaInvoiceList: [],
    selectedInvoice: null
}

export const ProformaInvoiceSlice = createSlice({
    name: 'proforma-invoice',
    initialState,
    reducers: {
        addProformaInvoiceToList: (state, action) => {
            state.proformaInvoiceList.forEach(invoice => {
                invoice.active = false;
            })
            state.proformaInvoiceList = [action.payload, ...state.proformaInvoiceList];
            state.selectedInvoice = action.payload;
        },
        setProformaInvoiceList: (state, action) => {
            state.proformaInvoiceList = action.payload.sort((i1,i2)=>{return i2.invoiceNumber-i1.invoiceNumber});
        },
        setSelectedInvoice: (state, action) => {
            state.selectedInvoice = action.payload;
        }
    }
})

export const { addProformaInvoiceToList, setProformaInvoiceList, setSelectedInvoice } = ProformaInvoiceSlice.actions;

export const addProformaInvoice = (instance, orderId) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.proformaInvoices.post(orderId);
        dispatch(addProformaInvoiceToList(response));
        return response
    }
    catch (error){
        throw error;
    }
}

export const fetchProformaInvoices = (instance, orderId) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.proformaInvoices.get(orderId);
        dispatch(setProformaInvoiceList(response));
        return response
    }
    catch (error){
        throw error;
    }
}

export default ProformaInvoiceSlice.reducer;