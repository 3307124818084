import { createSlice } from "@reduxjs/toolkit"
import ApiClientConnector from "src/api/ApiClientConnector";
import { convertToKeyValueList } from "src/utils/common";

const initialState = {
    countryList: [],
    countries: {}
}

export const CountrySlice = createSlice({
    name: "country",
    initialState,
    reducers: {
        getCountries: (state, action) => {
            state.countryList = action.payload;
            state.countries = convertToKeyValueList(action.payload, 'id');
        }
    }
})

export const { getCountries } = CountrySlice.actions;

export const fetchCountries = (instance, criteria) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.countries.get(criteria);
        dispatch(getCountries(response));
        return response;
    }
    catch (error){
        throw error;
    }
}

export default CountrySlice.reducer;