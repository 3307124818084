import { createSlice } from '@reduxjs/toolkit';
import ApiClientConnector from 'src/api/ApiClientConnector';

const initialState = {
    activatedUnits: null,
    adjustedQuantity: null,
};

export const StockItemUnitsSlice = createSlice({
    name: 'stock-item-units',
    initialState,
    reducers: {
        activateUnits: (state, action) => {
            state.activatedUnits = action.payload;
        },
        adjustQuantity: (state, action) => {
            state.adjustedQuantity = action.payload;
        },
    },
});

export const { activateUnits, adjustQuantity } = StockItemUnitsSlice.actions;

export const activateStockItemUnits = (instance, data) => async (dispatch) => {
    try {
        const client = await ApiClientConnector(instance);
        const response = await client.stockItemUnits.activate(data);
        dispatch(activateUnits(response));
        return response;
    } catch (error) {
        throw error;
    }
};

export const adjustStockItemUnitsQuantity = (instance, id, data) => async (dispatch) => {
    try {
        const client = await ApiClientConnector(instance);
        const response = await client.stockItemUnits.adjustQuantity(id, data);
        dispatch(adjustQuantity(response));
        return response;
    } catch (error) {
        throw error;
    }
};

export default StockItemUnitsSlice.reducer;