import { createSlice } from "@reduxjs/toolkit"
import ApiClientConnector from "src/api/ApiClientConnector";

const initialState = {
    companySettings: null
}

export const CompanySettingsSlice = createSlice({
    name: "company-settings",
    initialState,
    reducers: {
        getCompanySettings: (state, action) => {
            state.companySettings = action.payload;
        },
        updateProductionSettings: (state, action) => {
            state.companySettings = {...action.payload};
        }
    }
})

export const { getCompanySettings, updateProductionSettings } = CompanySettingsSlice.actions;

export const fetchCompanySettings = (instance, companyId) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.companySettings.get(companyId);
        dispatch(getCompanySettings(response));
        return response;
    }
    catch (error){
        throw error;
    }
}

export const updateCompanyProductionSettings = (instance, companyId, data) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.companySettings.postProductionSettings(companyId, data);
        dispatch(updateProductionSettings(response));
        return response;
    }
    catch (error){
        throw error;
    }
}

export default CompanySettingsSlice.reducer;