import appConfig, { loginRequest } from "../authConfig";
import SmartFlowApiClient from './SmartFlowApiClient';

const ApiClientConnector = async (instance) => {
    let account = instance.getActiveAccount();
    if (!account) {
        const accounts = instance.getAllAccounts();
        if(accounts && accounts.length > 0){
            instance.setActiveAccount(accounts[0]);
        }
    }
    account = instance.getActiveAccount();
    if(!account){
        throw Error("Account not found");
    }
    var token = "";
    const request = { account: account, scopes: loginRequest.scopes }
    await instance.acquireTokenSilent(request)
      .then((response) => {
        token = response.accessToken;
      })
      .catch(async (e) => {
        await instance.acquireTokenPopup(request).then((response) => {
          token = response.accessToken;
      });
    });
    const tenantId = localStorage.getItem('tenantId') != null ? localStorage.getItem('tenantId') : "";
    const client = new SmartFlowApiClient(
      appConfig.apiBaseUrl,
      token,
      tenantId
    );
    return client;
}

export default ApiClientConnector;
