import { createSlice } from "@reduxjs/toolkit"
import ApiClientConnector from "src/api/ApiClientConnector";

const initialState = {
    bankAccountList: [],
    selectedBankAccount: null
}

export const CompanyBankAccountSlice = createSlice({
    name: "company-bank-account",
    initialState,
    reducers: {
        getCompanyBankAccounts: (state, action) => {
            state.bankAccountList = action.payload;
        },
        addCompanyBankAccount: (state, action) => {
            state.bankAccountList = [...state.bankAccountList, {...action.payload}];
        },
        updateExistCompanyBankAccount: (state, action) => {
            var index = state.bankAccountList.findIndex(i => i.id === action.payload.id);
            state.bankAccountList[index] = {...action.payload};
        },
        removeCompanyBankAccount: (state, action) => {
            var index = state.bankAccountList.findIndex(i => i.id === action.payload.id);
            state.bankAccountList.splice(index, 1);
        },
        setSelectedBankAccount: (state, auction) => {
            state.selectedBankAccount = auction.payload;
        }
    }
})

export const { getCompanyBankAccounts, addCompanyBankAccount, updateExistCompanyBankAccount, removeCompanyBankAccount, setSelectedBankAccount } = CompanyBankAccountSlice.actions;

export const fetchCompanyBankAccounts = (instance, companyId) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.companyBankAccounts.get(companyId);
        dispatch(getCompanyBankAccounts(response));
        return response;
    }
    catch (error){
        throw error;
    }
}

export const createCompanyBankAccount = (instance, companyId, data) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.companyBankAccounts.post(companyId, data);
        dispatch(addCompanyBankAccount(response));
        return response;
    }
    catch (error){
        throw error;
    }
}

export const updateCompanyBankAccount = (instance, companyId, data) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.companyBankAccounts.put(companyId, data.id, data);
        dispatch(updateExistCompanyBankAccount(response));
        return response;
    }
    catch (error){
        throw error;
    }
}

export const deleteCompanyBankAccount = (instance, companyId, id) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.companyBankAccounts.delete(companyId, id);
        dispatch(removeCompanyBankAccount(response));
        return response;
    }
    catch (error){
        throw error;
    }
}

export default CompanyBankAccountSlice.reducer;