import { createSlice } from "@reduxjs/toolkit";
import ApiClientConnector from "src/api/ApiClientConnector";

const initialState = {
    addressList: [],
    selectedAddress: null
}

export const CompanyAddressSlice = createSlice({
    name: "company-address",
    initialState,
    reducers: {
        getCompanyAddresses: (state, action) => {
            state.addressList = action.payload;
        },
        addNewCompanyAddress: (state, action) => {
            state.addressList = [...state.addressList, {...action.payload}];
        },
        updateExistsCompanyAddress: (state, action) => {
            var index = state.addressList.findIndex(i => i.id === action.payload.id);
            state.addressList[index] = {...action.payload};
        },
        setSelectedAddress: (state, action) => {
            state.selectedAddress = action.payload;
        }
    }
})

export const {getCompanyAddresses, addNewCompanyAddress, updateExistsCompanyAddress, setSelectedAddress} = CompanyAddressSlice.actions;

export const fetchCompanyAddresses = (instance, companyId, criteria) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.companyAddresses.get(companyId, criteria);
        dispatch(getCompanyAddresses(response));
        return response;
    }
    catch (error){
        throw error;
    }
}

export const addCompanyAddress = (instance, companyId, data) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.companyAddresses.post(companyId, data);
        dispatch(addNewCompanyAddress(response));
        return response;
    }
    catch (error){
        throw error;
    }
}

export const updateCompanyAddress = (instance, companyId, data) => async (dispatch) =>{
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.companyAddresses.put(companyId, data.id, data);
        dispatch(updateExistsCompanyAddress(response));
        return response;
    }
    catch (error){
        throw error;
    }
}

export default CompanyAddressSlice.reducer;