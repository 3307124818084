import { createSlice } from "@reduxjs/toolkit";
import ApiClientConnector from "src/api/ApiClientConnector";

const initialState = {
    certificationsList: [],
    selectedCertification: null
};

export const ProductCertificationsSlice = createSlice({
    name: 'productCertifications',
    initialState,
    reducers: {
        getCertifications: (state, action) => {
            state.certificationsList = action.payload;
        }
    }
});

export const { getCertifications } = ProductCertificationsSlice.actions;

export const fetchCertifications = (instance, criteria) => async (dispatch) => {
    try {
        const client = await ApiClientConnector(instance);
        const response = await client.productCertifications.get(criteria);
        dispatch(getCertifications(response));
        return response;
    } catch (error) {
        throw error;
    }
};

export default ProductCertificationsSlice.reducer;