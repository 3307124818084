import { createSlice } from "@reduxjs/toolkit";
import ApiClientConnector from "src/api/ApiClientConnector";

const initialState = {
    customerList: [],
    selectedCustomerId: null,
    selectedCustomer: null,
    isDetailView: false,
    isManageView: false,
};

export const CustomerManageSlice = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        getCustomers: (state, action) => {
            state.customerList = action.payload;
        },
        addNewCustomer: (state, action) => {
            state.customerList = [...state.customerList, action.payload];
        },
        updateCustomerDetails: (state, action) => {
            var index = state.customerList.findIndex(i => i.id === action.payload.id);
            state.customerList[index] = {...action.payload};
        },
        changeSelectedCustomerId: (state, action) => {
            state.selectedCustomerId = action.payload;
        },
        setSelectedCustomer: (state, action) => {
            state.selectedCustomer = action.payload;
        },
        changeDetailView: (state, action) => {
            state.isDetailView = action.payload;
        },
        changeManageView: (state, action) => {
            state.isManageView = action.payload;
        }
    }
})

export const { getCustomers, addNewCustomer, updateCustomerDetails, changeSelectedCustomerId, changeDetailView, changeManageView, setSelectedCustomer } = CustomerManageSlice.actions;

export const fetchCustomers = (instance, criteria) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.customers.get(criteria);
        dispatch(getCustomers(response))
        return response;
    } catch (error) {
        throw error;
    }
}

export const addCustomer = (instance, data) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.customers.post(data);
        dispatch(addNewCustomer(response));
        dispatch(setSelectedCustomer(response));
        return response.id;
    } catch (error){
        throw error;
    }
}

export const updateCustomer = (instance, data) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.customers.put(data);
        dispatch(updateCustomerDetails(response));
        dispatch(setSelectedCustomer(response));
        return response.id;
    } catch (error){
        throw error;
    }
}

export const fetchCustomer = (instance, id) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.customers.getById(id);
        dispatch(setSelectedCustomer(response));
        return response;
    } catch (error){
        throw error;
    }
}

export default CustomerManageSlice.reducer;