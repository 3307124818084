import { createSlice } from "@reduxjs/toolkit";
import ApiClientConnector from "src/api/ApiClientConnector";

const initialState = {
    supplierList: [],
    selectedSupplier: null,
    isDetailView: false,
    isManageView: false,
    selectedSupplierId: null
};

export const SupplierSlice = createSlice({
    name: 'supplier',
    initialState,
    reducers: {
        getSuppliers: (state, action) => {
            state.supplierList = action.payload;
        },
        addNewSupplier: (state, action) => {
            state.supplierList = [...state.supplierList, {...action.payload}];
        },
        updateExistsSupplier: (state, action) => {
            const index = state.supplierList.findIndex(i => i.id === action.payload.id);
            state.supplierList[index] = {...action.payload};
        },
        setSelectedSupplier: (state, action) => {
            state.selectedSupplier = action.payload;
        },
        changeSelectedSupplierId: (state, action) => {
            state.selectedSupplierId = action.payload;
        },
        changeDetailView: (state, action) => {
            state.isDetailView = action.payload;
        },
        changeManageView: (state, action) => {
            state.isManageView = action.payload;
        }
    }
})

export const { getSuppliers, addNewSupplier, updateExistsSupplier, setSelectedSupplier, changeSelectedSupplierId, changeDetailView, changeManageView } = SupplierSlice.actions;

export const fetchSuppliers = (instance, criteria) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.suppliers.get(criteria);
        dispatch(getSuppliers(response))
        return response;
    } catch (error) {
        throw error;
    }
}

export const addSupplier = (instance, data) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.suppliers.post(data);
        dispatch(addNewSupplier(response));
        dispatch(setSelectedSupplier(response));
        return response;
    }
    catch (error){
        throw error;
    }
}

export const updateSupplier = (instance, id, data) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.suppliers.put(id, data);
        dispatch(updateExistsSupplier(response));
        dispatch(setSelectedSupplier(response));
        return response;
    }
    catch (error){
        throw error;
    }
}

export const fetchSupplier = (instance, id) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.suppliers.getById(id);
        dispatch(setSelectedSupplier(response));
        return response;
    } catch (error){
        throw error;
    }
}

export default SupplierSlice.reducer;