import { createSlice } from "@reduxjs/toolkit"
import ApiClientConnector from "src/api/ApiClientConnector";

const initialState = {
    companyProfiles: [],
    selectedProfile: null
}

export const CompanyProfileSlice = createSlice({
    name: "company-profile",
    initialState,
    reducers: {
        getCompanyProfiles: (state, action) => {
            state.companyProfiles = action.payload;
            if (action.payload.length === 1){
                state.selectedProfile = action.payload[0];
            }
        },
        addNewCompanyProfile: (state, action) => {
            state.companyProfiles = [...state.companyProfiles, {...action.payload}];
            state.selectedProfile = action.payload;
        },
        updateExistCompanyProfile: (state, action) => {
            var index = state.companyProfiles.findIndex(i => i.id === action.payload.id);
            state.companyProfiles[index] = {...action.payload};
            state.selectedProfile = action.payload;
        },
        setSelectedProfile: (state, action) => {
            state.selectedProfile = action.payload;
        }
    }
})

export const { getCompanyProfiles, addNewCompanyProfile, updateExistCompanyProfile, setSelectedProfile } = CompanyProfileSlice.actions;

export const fetchCompanyProfiles = (instance, criteria) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.companyProfiles.get(criteria);
        dispatch(getCompanyProfiles(response));
        return response;
    }
    catch (error){
        throw error;
    }
}

export const addCompanyProfile = (instance, data) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.companyProfiles.post(data);
        dispatch(addNewCompanyProfile(response));
        return response;
    }
    catch (error){
        throw error;
    }
}

export const updateCompanyProfile = (instance, data) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.companyProfiles.put(data.id, data);
        dispatch(updateExistCompanyProfile(response));
        return response;
    }
    catch (error){
        throw error;
    }
}

export const uploadCompanyLogo = (instance, companyId, data) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.companyProfiles.uploadLogo(companyId, data);
        dispatch(updateExistCompanyProfile(response));
        return response;
    }
    catch (error){
        throw error;
    }
}

export default CompanyProfileSlice.reducer;