import { createSlice } from "@reduxjs/toolkit";
import ApiClientConnector from "src/api/ApiClientConnector";

const initialState = {
    addresses: [],
    selectedAddress: null
};

export const CustomerAddressSlice = createSlice({
    name: 'customer-addresses',
    initialState,
    reducers: {
        getCustomerAddresses: (state, action) => {
            state.addresses = action.payload;
        },
        addNewCustomerAddress: (state, action) => {
            state.addresses = [...state.addresses, {...action.payload}];
        },
        updateExistCustomerAddress: (state, action) => {
            const index = state.addresses.findIndex(i => i.id === action.payload.id);
            state.addresses[index] = {...action.payload};
        },
        setSelectedCustomerAddress: (state, action) => {
            state.selectedAddress = action.payload;
        }
    }
})

export const { getCustomerAddresses, addNewCustomerAddress, updateExistCustomerAddress, setSelectedCustomerAddress } = CustomerAddressSlice.actions;

export const fetchCustomerAddresses = (instance, customerId, criteria) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.customerAddresses.get(customerId, criteria)
        dispatch(getCustomerAddresses(response));
        return response;
    } catch (error){
        throw error;
    }
}

export const addCustomerAddress = (instance, customerId, data) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.customerAddresses.post(customerId, data);
        dispatch(addNewCustomerAddress(response));
        return response;
    }
    catch (error){
        throw error;
    }
}

export const updateCustomerAddress = (instance, customerId, data) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.customerAddresses.put(customerId, data.id, data);
        dispatch(updateExistCustomerAddress(response));
        return response;
    }
    catch (error){
        throw error;
    }
}

export default CustomerAddressSlice.reducer;