import React, { useEffect, useState } from "react";
import { BrowserRouter, useRoutes } from 'react-router-dom';
import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react';
import { InteractionType } from "@azure/msal-browser";
import Router from './routes/Router';
import { ThemeSettings } from './theme/Theme';
import ScrollToTop from './components/shared/ScrollToTop';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { loginRequest } from './authConfig';
import useSessionStorageNonString from "./hooks/useSessionStorageNonString";
import useLocalStorage from "./hooks/useLocalStorage";
import apiClient from "./api/ApiClientConnector";
import Error from "./views/authentication/Error";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from "./views/spinner/Spinner";

const ProfileContent =  () => {
  const { instance } = useMsal();
  const [profile, setProfile] = useSessionStorageNonString('user-profile', {});
  const [tenantId, setTenantId] = useLocalStorage("tenantId", '00000000-0000-0000-0000-000000000000');
  const [error, setError] = useState(null);

  useEffect(() => {
    const getUserProfile = async () => {
      try {
        const client = await apiClient(instance);
        const result = await client.userProfile.get();
        setProfile(result);
      } catch(e) {
        setError(e);
      }
    }
    getUserProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[instance]);



  useEffect(() => {
    async function setCurrentTenant() {
      if (profile && profile.tenants) {
        let myTenant = profile.tenants.find((i) => i.id === tenantId);
        if (!myTenant) {
          myTenant = profile.tenants[0];
        }
        setTenantId(myTenant.id);
      }
    }
    setCurrentTenant();
  }, [profile, setTenantId, tenantId]);
   
  const routing = useRoutes(Router);
  const theme = ThemeSettings();
  
  if (error != null) {
    return <Error />;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ScrollToTop>{routing}</ScrollToTop>
    </ThemeProvider>
  );
};

const ProtectedComponent = ({ children }) => {
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={loginRequest}
      errorComponent={ErrorComponent}
      loadingComponent={LoadingComponent}
    >
        {children}
    </MsalAuthenticationTemplate>
  );
};

function ErrorComponent({ error }) {
  return <p>An Error Occurred: {JSON.stringify(error)}</p>;
}

function LoadingComponent() {
  return  <Spinner />
}

function App() {
  return (
    <BrowserRouter>
      <ProtectedComponent>
          <ProfileContent />
          <ToastContainer />
      </ProtectedComponent>
    </BrowserRouter>
  );
}

export default App;
