import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';

import Loadable from '../layouts/full/shared/loadable/Loadable';

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank/BlankLayout')));

// Dashboard
const Dashboard = Loadable(lazy(() => import('../views/dashboard/Dashboard')));
const Customers = Loadable(lazy(() => import('../views/customers/CustomersView')));
const AuditEvents = Loadable(lazy(() => import('../views/audit-events/AuditEventLog')));
const StandardsView = Loadable(lazy(() => import('../views/standards/StandardsView')));
const OrdersPage = Loadable(lazy(() => import('../views/Order/OrderView')));
const BlendSheetsView = Loadable(lazy(() => import('../views/blend-sheets/BlendSheetsView')));
const StockItemsPage =  Loadable(lazy(() => import('../views/stock-items/StockItemsView')));
const UsersView = Loadable(lazy(() => import('../views/users/UsersView')));
const SettingsView = Loadable(lazy(() => import('../views/settings/SettingsView')));
const CollectionsView = Loadable(lazy(() => import('../views/collections/CollectionsView')));
const FacilityView = Loadable(lazy(() => import('../views/facility/FacilityView')));
const ReceivingView = Loadable(lazy(() => import('../views/receiving/ReceivingView')));
const ProductView = Loadable(lazy(() => import('../views/npd/ProductView')));
const SuppliersView = Loadable(lazy(() => import('../views/suppliers/SuppliersView')));

const Error = Loadable(lazy(() => import('../views/authentication/Error')));
const Maintenance = Loadable(lazy(() => import('../views/authentication/Maintenance')));

const Router = [
  {
    path: '/',
    element: <FullLayout />,
    children: [
      { path: '/', element: <Navigate to="/dashboard" /> },
      { path: '/dashboard', element: <Dashboard /> },
      { path: '/welcome', element: <Dashboard /> },
      { path: '/customers', element: <Customers /> },
      { path: '/standards', element: <StandardsView /> },
      { path: '/orders', element: <OrdersPage /> },
      { path: '/blend-sheets', element: <BlendSheetsView /> },
      { path: '/stock-items', element: <StockItemsPage /> },
      { path: '/warehouses', element: <CollectionsView /> },
      { path: '/facilities', element: <FacilityView /> },
      { path: '/receiving', element: <ReceivingView /> },
      { path: '/products', element: <ProductView /> },
      { path: '/suppliers', element: <SuppliersView /> },
      { path: '/audit-events', element: <AuditEvents /> },
      { path: '/users', element: <UsersView /> },
      { path: '/settings', element: <SettingsView /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: '/',
    element: <BlankLayout />,
    children: [
      { path: '/auth/404', element: <Error /> },
      { path: '/auth/maintenance', element: <Maintenance /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
];

export default Router;
