import { createSlice } from "@reduxjs/toolkit"
import ApiClientConnector from "src/api/ApiClientConnector";

const initialState = {
    companyContacts: [],
    selectedCompanyContact: null
}

export const CompanyContactSlice = createSlice({
    name: "company-contacts",
    initialState,
    reducers: {
        getCompanyContacts: (state, action) => {
            state.companyContacts = action.payload;
        },
        deleteCompanyContact: (state, action) => {
            const index =state.companyContacts.findIndex((companyContacts) => companyContacts.id === action.payload.id);
            state.companyContacts.splice(index, 1);
        },
        addNewCompanyContact: (state, action) => {
            state.companyContacts = [...state.companyContacts, {...action.payload}];
        },
        updateExistCompanyContact: (state, action) => {
            const index = state.companyContacts.findIndex(i => i.id === action.payload.id);
            state.companyContacts[index] = {...action.payload};
        },
        setSelectedCompanyContact: (state, action) => {
            state.selectedCompanyContact = action.payload;
        }
    }
})
export const { getCompanyContacts, deleteCompanyContact, addNewCompanyContact, updateExistCompanyContact, setSelectedCompanyContact} = CompanyContactSlice.actions;

export const fetchCompanyContacts = (instance, companyProfileId) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.companyContacts.get(companyProfileId);
        dispatch(getCompanyContacts(response));
        return response;
    }
    catch (error){
        throw error;
    }
}

export const addCompanyContact = (instance, data, companyProfileId) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.companyContacts.post(data, companyProfileId);
        dispatch(addNewCompanyContact(response));
        return response;
    }
    catch (error){
        throw error;
    }
}

export const updateCompanyContact = (instance,companyProfileId, data) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.companyContacts.put(companyProfileId, data.id, data);
        dispatch(updateExistCompanyContact(response));
        return response;
    }
    catch (error){
        throw error;
    }
}

export const removeCompanyContact = (instance, contactId, companyProfileId) => async (dispatch) => {
    try {
        const client = await ApiClientConnector(instance);
        const response = await client.companyContacts.delete(companyProfileId, contactId);
        dispatch(deleteCompanyContact(response));
    } catch (error) {
        throw error;
    }
}
export default CompanyContactSlice.reducer;